<template>
  <div>
    <FieldConditions
      v-model="validation.required"
      :widget="widget"
      :label="$t('fields.general.required.label')"
      :tooltip="$t('fields.general.required.info')"
      :error-message-label="$t('fields.general.required.error-message')"
    />

    <FieldConditions
      v-model="validation.readOnly"
      :widget="widget"
      :label="$t('fields.general.read-only.label')"
      :show-error-message="false"
    />

    <div class="form-group__validation-last-field-margin">
      <b-form-group>
        <b-form-checkbox v-model="validation.matchingRegex.isEnabled">
          {{ $t('fields.codex-field-url.validation.matching-regex.is-enabled-label') }}
          <GjIcon
            v-b-tooltip.hover="$t('fields.codex-field-url.validation.matching-regex.is-enabled-tooltip')"
            name="Info"
            size="20"
          />
        </b-form-checkbox>
      </b-form-group>
      <template v-if="validation.matchingRegex.isEnabled">
        <div class="text-field__patterns-section">
          <b-form-input
            id="at-match-regex-word-input"
            v-model.trim="matchWord"
            :placeholder="$t('fields.codex-field-url.validation.matching-regex.pattern-placeholder')"
            :state="matchPatternState"
            @keypress.enter="handleInputChange"
          />
          <span
            class="at-prohibit-regex-word__button"
            @click="handleAddText"
          >
            <GjIcon
              size="18"
              style="margin-top: 2px; fill: #1D79F2"
              name="Plus"
            />
            {{ $t('fields.codex-field-url.validation.matching-regex.pattern-value-add') }}
          </span>
        </div>
        <b-form-invalid-feedback id="input-live-feedback">
          {{ $t('fields.codex-field-url.validation.matching-regex.pattern-error') }}
        </b-form-invalid-feedback>
        <div class="text-field__given-patterns">
          <div
            v-for="(cmt, idx) in validation.matchingRegex.patterns"
            :key="idx"
            class="text-field__given-pattern"
          >
            <p class="text-field__pattern-bubble">
              {{ cmt }}
            </p>
            <GjIcon
              class="at-match-regex-remove-word-icon"
              name="Close"
              style="margin-right: 8px; cursor: pointer;"
              @click.native="removeMatchWord(cmt)"
            />
          </div>
        </div>
        <b-form-group :label="$t('fields.codex-field-url.validation.matching-regex.flags')">
          <v-select
            v-model="validation.matchingRegex.flags"
            multiple
            :options="flagsOptions"
            :clearable="false"
            :reduce="v => v.value"
          />
        </b-form-group>
        <b-form-group
          :label="$t('fields.codex-field-url.validation.matching-regex.custom-error-message')"
          class="form-group__validation-last-field-margin"
        >
          <b-form-input
            v-model="validation.matchingRegex.errorMessage"
            :placeholder="$t('fields.codex-field-url.validation.matching-regex.custom-error-message')"
          />
        </b-form-group>
      </template>
    </div>

    <b-form-group :label="$t('fields.codex-field-url.validation.statuses')">
      <b-form-checkbox
        :checked="checked(ENTRY_STATUSES.DRAFT)"
        @input="check(ENTRY_STATUSES.DRAFT)"
      >
        {{ $t(mapEntryStatus(ENTRY_STATUSES.DRAFT)) | capitalize }}
      </b-form-checkbox>
      <b-form-checkbox
        :checked="checked(ENTRY_STATUSES.PUBLISHED)"
        @input="check(ENTRY_STATUSES.PUBLISHED)"
      >
        {{ $t(mapEntryStatus(ENTRY_STATUSES.PUBLISHED)) | capitalize }}
      </b-form-checkbox>
      <b-form-checkbox
        :checked="checked(ENTRY_STATUSES.SCHEDULED)"
        @input="check(ENTRY_STATUSES.SCHEDULED)"
      >
        {{ $t(mapEntryStatus(ENTRY_STATUSES.SCHEDULED)) | capitalize }}
      </b-form-checkbox>
      <b-form-checkbox
        :checked="checked(ENTRY_STATUSES.UNPUBLISHED)"
        @input="check(ENTRY_STATUSES.UNPUBLISHED)"
      >
        {{ $t(mapEntryStatus(ENTRY_STATUSES.UNPUBLISHED)) | capitalize }}
      </b-form-checkbox>
      <b-form-checkbox
        :checked="checked(ENTRY_STATUSES.EDITED)"
        @input="check(ENTRY_STATUSES.EDITED)"
      >
        {{ $t(mapEntryStatus(ENTRY_STATUSES.EDITED)) | capitalize }}
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import { ENTRY_STATUSES, mapEntryStatus } from '@/codex-sdk/entries'
import { FLAGS } from '@/views/models/constants'
import FieldConditions from '@/components/fields/FieldConditions.vue'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'

export default {
  name: 'Validations',
  components: { FieldConditions },
  mixins: [BaseFieldMixin],
  data() {
    return {
      mapEntryStatus,
      ENTRY_STATUSES,

      matchWord: '',
      matchPatternState: null,
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'validation',
      'valueType',
      'appearance',
      'editableIn',
    ]),
    flagsOptions() {
      return [{ label: this.$t('fields.general.flag-options.ignore-case'), value: FLAGS.IGNORE_CASE },
      ]
    },
  },
  methods: {
    check(status) {
      if (this.editableIn.includes(status)) {
        this.editableIn = this.editableIn.filter(s => s != status)
      } else {
        this.editableIn.push(status)
      }
    },
    checked(status) {
      return this.editableIn.includes(status)
    },
    handleInputChange() {
      this.matchPatternState = null
      this.handleAddText()
    },
    handleAddText() {
      if (!this.matchWord.length) return
      if (this.validation.matchingRegex.patterns.includes(this.matchWord)) {
        this.matchPatternState = false
        return
      }

      this.validation.matchingRegex.patterns.push(this.matchWord)
      this.matchWord = ''
    },
    removeMatchWord(cmt) {
      this.validation.matchingRegex.patterns = this.validation.matchingRegex.patterns.filter(c => c !== cmt)
    },
  },
}
</script>

<style lang="scss" scoped>
.text-field__pattern-bubble {
  margin-bottom: 0;
  padding: 4px 2px 4px 16px;
  font-size: 14px;
  color: #667C99;
  flex-wrap: wrap;
  word-break: break-all;
}
.text-field__given-patterns {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 10px;
}
.text-field__given-pattern {
  display: flex;
  align-items: center;

  background-color: #fff;
  border: 1px solid #e0e5eb;
  border-radius: 24px;

  svg {
    min-width: 24px;
  }
}

.text-field__patterns-section {
  position: relative;
  margin-top: 8px;
  margin-bottom: 10px;
  #at-prohibit-regex-word-input{
    width: 100%;
    padding-right: 64px;
  }

  .at-prohibit-regex-word__button{
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translate(0%, -50%);

    display: flex;
    align-content: center;

    cursor: pointer;

    color: #1D79F2;
    font-weight: 600;
  }
}
</style>
