import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn } from '@/auth/utils'
import { authRoutes, authRoutesGuard } from '@/../gjirafa-front/script/auth'
import store from '@/store'
import { routerGuards as siteServiceRouterGuards } from '../services/siteService'
import siteLevelRoutes from './site-level-routes'
import organizationLevelRoutes from './organization-level-routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.name === 'images' && from.name === 'images' && to.query.folder === from.query.folder) {
      return null
    }
    // Prevent scroll to top when creating an entry
    if (to.name === 'entries-edit' && from.name === 'entries-edit' && from.params.id === 'create') {
      return null
    }
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: () => {
        let lastRoute = localStorage.getItem('last-route')
        if (lastRoute) {
          lastRoute = JSON.parse(lastRoute)

          const props = router.resolve(lastRoute)

          localStorage.removeItem('last-route')

          if (props.route.params.orgName) {
            return { path: props.href }
          }
        }

        return { name: 'organizations' }
      },
    },

    ...authRoutes,

    // Global Routes

    {
      path: '/organizations',
      name: 'organizations',
      component: () => import('@/views/organizations/Listing.vue'),
      meta: {
        hideMenu: true,
        pageTitle: 'Organizations',
        breadcrumb: [],
        headerLogo: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/pages/error/Error404.vue'),
      meta: {
        isPublic: true,
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/error-500',
      name: 'error-500',
      component: () => import('@/views/pages/error/Error500.vue'),
      meta: {
        isPublic: true,
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/error-500',
      name: 'misc-not-authorized',
      component: () => import('@/views/pages/error/Error500.vue'),
      meta: {
        isPublic: true,
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/pages/auth/Login.vue'),
      meta: {
        isPublic: true,
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/pages/auth/Logout.vue'),
      meta: {
        isPublic: true,
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/:orgName/user/invitation/:inv',
      name: 'user-invitation',
      component: () => import('@/views/callback/Listing.vue'),
      meta: {
        isPublic: true,
        organizationRequired: true,
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('@/views/pages/error/ErrorGeneral.vue'),
      meta: {
        isPublic: true,
        organizationRequired: true,
        layout: 'full',
        resource: 'Auth',
      },
    },

    // Organization Routes

    ...organizationLevelRoutes,

    // Site Routes

    ...siteLevelRoutes,

    // 404

    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

authRoutesGuard(router)
router.afterEach((to, from) => {
  if (from.name === 'images' && to.name !== 'images') {
    store.dispatch('general/setBreadcrumbItems', [])
  }
})

window.router = router

siteServiceRouterGuards(router)

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!to.meta.isPublic && !canNavigate(to) && false) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      return next({ name: 'auth-login', params: { redirect: `${window.location.pathname}${window.location.search}` } })
    }

    // If logged in => not authorized
    // For now redirect to organizations
    return next('/organizations')
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next('/organizations')
  }

  if (to.meta.hideMenu) {
    store.state.appConfig.layout.menu.hidden = true
  } else {
    store.state.appConfig.layout.menu.hidden = false
  }

  if (to.meta.collapseSidebar) {
    store.state.verticalMenu.isVerticalMenuCollapsed = true
  } else {
    store.state.verticalMenu.isVerticalMenuCollapsed = false
  }

  return next()
})

export default router
