import { FIELD_GROUPS } from '@/codex-sdk/models'
import { ENTRY_STATUSES } from '@/codex-sdk/entries'
import { registerField } from '@/components/codex-layout-editor/BuilderUtils'
import {
  TYPES,
  VALUE_TYPES,
  RANGE_OPERATORS,
  FIELD_FILTER_OPERATORS,
} from '@/views/models/constants'
import Render from './Render.vue'
import General from './General.vue'
import Initial from './Initial.vue'
import Validations from './Validations.vue'
import Appearance from './Appearance.vue'
import Configuratino from './Configuration.vue'

registerField('codex_field', {
  displayName: 'Sections',
  name: 'sections',
  render: Render,
  renderEditor: Render,
  renderEditorPanel: [
    {
      icon: 'Settings', label: 'fields.codex-field-sections.tabs.general', component: General, isGeneral: true,
    },
    { icon: 'Brush', label: 'fields.codex-field-sections.tabs.appearance', component: Appearance },
    { icon: 'Tasks', label: 'fields.codex-field-sections.tabs.validations', component: Validations },
    { icon: 'SettingsPreferencesTools', label: 'fields.codex-field-sections.tabs.configuration', component: Configuratino },
  ],
  renderEditorInitialPanel: [
    { icon: 'Settings', label: 'fields.codex-field-sections.tabs.initial', component: Initial },
  ],

  group: FIELD_GROUPS.CODEX,
  icon: 'field-section.png',

  type: TYPES.SECTION,
  valueType: VALUE_TYPES.SINGLE,

  attrs: {
    appearance: {
      default: 1,
    },
    helpText: {
      default: '',
    },
    helpTextDisplay: {
      default: 'tooltip',
    },
    mainEditableIn: {
      default: [ENTRY_STATUSES.DRAFT, ENTRY_STATUSES.UNPUBLISHED, ENTRY_STATUSES.PUBLISHED, ENTRY_STATUSES.SCHEDULED, ENTRY_STATUSES.EDITED],
    },
    editableIn: {
      default: [ENTRY_STATUSES.DRAFT, ENTRY_STATUSES.UNPUBLISHED, ENTRY_STATUSES.PUBLISHED, ENTRY_STATUSES.SCHEDULED, ENTRY_STATUSES.EDITED],
    },
    validation: {
      default: {
        required: {
          value: false,
          errorMessage: 'Section Field Required.',
          conditionsEnabled: false,
          conditions: [
            {
              isSystem: false,
              field: '',
              operator: FIELD_FILTER_OPERATORS.EXISTS,
              value: '',
            },
          ],
        },
        readOnly: {
          value: false,
          conditionsEnabled: false,
          conditions: [
            {
              isSystem: false,
              field: '',
              operator: FIELD_FILTER_OPERATORS.EXISTS,
              value: '',
            },
          ],
        },
        numberOfSections: {
          isEnabled: false,
          rangeOperator: RANGE_OPERATORS.BETWEEN,
          min: 1,
          max: 2,
          exactly: 0,
          errorMessage: 'Number of Sections is Incorrect',
        },
        parentChildValidation: {
          restrictParentChildSelection: false,
          errorMessage: 'You cant select Sections which have parent or children selected',
        },
        invalidAuth: 'Invalid Section Input',
      },
    },
    configuration: {
      default: {
        searchable: true,
        filterable: true,
      },
    },
    hidden: {
      value: false,
      conditionsEnabled: false,
      conditions: [
        {
          isSystem: false,
          field: '',
          operator: FIELD_FILTER_OPERATORS.EXISTS,
          value: '',
        },
      ],
    },
  },
})
